.p-20 {
    padding: 20px;
}

.card-header-content {
    border: 1px solid #241a7ed6;
    border-radius: 5px;
}

.h-amount {
    color: #0f1f52;
    font-size: 24px;
    font-weight: 600;
}

.h-exchange {
    color: #444;
    display: inline-block;
    font-size: 14px;
}

.footer-section .btn{
color:#000428 !important
}

.h-delegated {
    background-image: linear-gradient(
            180deg,#1f3d99,#241a7ed6);
    border-top: 1px solid #241a7ed6;
    color: #eceacc;
    border-radius: 0 0 3px 3px;
    padding: 7px 5px 5px;
}

.h-delegated-text {
    font-weight: 500;
    font-size: 16px;
    color: #eceacc;
}

.p-10 {
    padding: 10px;
}

.pt-20{
    padding-top: 20px;
}

.table-header{
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.table-body-content {
    background: #f3f3f3;
    border: 1px solid rgba(221,225,230,.575);
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: lightgray;
    font-weight: 500;
    font-size: 18px;
}
.custom-button {
    outline: unset;
    border: unset;
    background: #dde0d5;
    font-size: 14px;
    background-image: linear-gradient(180deg,#040421,#101f54);
    color: #fff;
    font-weight: 600;
    padding: 5px 9px;
    line-height: 20px;
    border-radius: 8px;
    border: 2px solid #fff;
    text-align: center;
}

.pt-100 {
    padding-top: 100px;
}

.pb-40 {
    padding-bottom: 40px;
}

.reward-button {
    background-image: linear-gradient(180deg,#040421,#101f54);
    border-radius: 15px;
    font-weight: 500;
    color: #fff;
}

.delegated-text{
    font-size: 18px;
    color: #0f1f52;
    font-weight: 600;
}

.amount-description {
    font-size: 16px;
    color: #0f1f52;
}

.delegated-icon {
    margin-right: 3px;
    height: 20px;
    vertical-align: middle;
}

.btn-hide-balance {
    color: #333;
    margin-right: 5px;
    padding: 0 0 0 5px;
    border-radius: 7px;
    border: 1px solid #e6e6e6;
    transition: all .3s ease;
    outline : none;
}

.footer-description {
    align-items: end;
    display: flex;
}

.twitter-icon {
    color : #00aced;
    margin-right : 5px;
    cursor : pointer;
}

.discord-icon {
    margin-top : 4px;
    cursor : pointer;
}
